import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import './Popup.css'; // Import the CSS file
import PopupImage from "../assets/popup.png";

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsVisible(true);
      document.getElementById("main-content").classList.add("blurred");
    } else {
      setIsVisible(false);
      document.getElementById("main-content").classList.remove("blurred");
    }
  }, [location]);

  const closePopup = () => {
    setIsVisible(false);
    document.getElementById("main-content").classList.remove("blurred");
  };

  return (
    <>
      {isVisible && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <img 
              src={PopupImage}
              alt="Popup Visual" 
              className="popup-image"
            />
            <div className="popup-description">
              <h2>IT'S SAUSAGE SUPPER TIME !!!</h2>
              <p>Come by 'Our Little Red Church' October 12th and celebrate 100 years of SAUSAGE SUPPER!</p>
              <button className="popup-close" onClick={closePopup}>Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;

